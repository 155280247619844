.questionnaireContent {
    width: 100%;
    min-height: 100%;
    background-color: #eaf2f7;
    background-image: url(../../assets/bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
    // padding-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .logoImg{
        display: block;
        width: 120px;
        // height: 40px;
        height: auto;
        position: absolute;
        top: 20px;
        left: 20px;
    }

    .headSplitDiv {
        height: 80px;
    }

    .questionnaireForm {
        width: 54%;
        // background: #fff;
        // margin: 0 auto !important;

        .voteHeader {
            // padding: 20px;
            // padding-top: 50px;
            // background: #fff;

            .voteHeaderTitle {
                line-height: 40px;
                font-weight: 600;
                -webkit-font-smoothing: antialiased;
                font-size: 24px;
                color: #fff;
                text-align: left;
                margin: 0;
                margin-bottom: 30px;
            }
        }

        .questionnaireFormContent {
            // padding: 0 30px 30px;
            background: #fff;
            border-radius: 10px;
            padding: 30px;

            .ant-form {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

            }

            .ant-form-item-label {
                label {
                    font-size: 17px !important;
                }

                font-weight: 500;
            }

            .ant-input {
                max-width: 100%;
            }

            .ant-form-item {
                margin-bottom: 30px;
            }
        }
    }

    .footerBtn {
        margin-top: 20px;
        margin-bottom: 50px;

        .ant-btn {
            width: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            outline: 0;
            cursor: pointer;
            text-align: center;
            text-decoration: none;
            font-size: 16px;
            height: 44px;
            line-height: 44px;
            font-weight: 500;
            border-radius: 2px;
            color: #fff;
            background: linear-gradient(225deg, #016BB2 0%, #34CADE 100%);
            border: none !important;
        }
    }

    .successBox {
        width: 100%;
        min-height: 300px;
        border-radius: 10px;
        // height: calc(100vh - 100px);
        background: #fff;
        margin: 0 auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        font-size: 18px;

        .countDownBox {
            font-size: 14px;
            text-align: center;
            margin-top: 24px;
        }
    }
}

@media screen and (max-width: 499px) {
    .questionnaireContent {
        padding-bottom: 0 !important;

        .headSplitDiv {
            display: none !important;
        }

        .successBox {
            height: 100vh;
        }
    }
}