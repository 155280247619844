.myHeader {
    height: 70px;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
    .logo {
        display: inline-block;
        // width: 180px;
        height: 40px;
    }

    .title {
        font-size: 18px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bolder;
        color: #222222;
        margin-left: 10px;
    }
}