.service {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    iframe {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
}