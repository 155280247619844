.myContent {
    width: 100%;
    height: calc(100% - 118px);
    margin-top: 24px;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;

    .searchFixedBox {
        position: fixed;
        top: 15px;
        right: 24px;
        // width: 40px;
        height: 40px;
        transition: width .5s;

        .iconSpanBox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: #d9d9d9 solid 1px;
            background: #efefef;
            font-size: 18px;
            cursor: pointer;

            &:hover {
                border-color: #0068b2;
            }
        }
    }

    .catalogueBox {
        // flex: none;
        min-width: 200px;
        width: 312px;
        height: 100%;
        background: #ffffff;
        border-radius: 10px;

        .headerLogo {
            height: 48px;
            width: 100%;
            background: linear-gradient(45deg, #006AB2 0%, #16D8D8 100%);
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px 10px 0 0;
            padding: 0 24px;
            box-sizing: border-box;

            .projectName {
                font-size: 16px;
                font-family: PingFang SC-粗体, PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
            }

            .moreProjects {
                color: #FFFFFF;
            }
        }

        .catalogueTreeBox {
            width: 100%;
            // height: calc(100% - 48px);
            padding: 20px;
            box-sizing: border-box;
            // overflow-y: auto;

            .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
            .ant-tree .ant-tree-checkbox+span.ant-tree-node-selected {
                background-color: #FFFFFF;
                color: #006AB2;
            }

            .ant-tree-node-content-wrapper {
                font-size: 14px;
                font-family: PingFang SC-常规, PingFang SC;
                font-weight: normal;
                color: #787878;
            }

            .ant-tree-switcher-icon {
                color: rgba(0, 0, 0, 0.5);
            }

            .treeItem {
                display: flex;

                img {
                    width: 16px;
                    height: 16px;
                }

                .treeItemImgSpan {
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .fileItem {
                position: relative;
                left: -22px;
            }
        }


    }

    .articleBox {
        margin-left: 24px;
        height: 100%;
        width: calc(100% - 336px);
        background: #ffffff;
        border-radius: 10px;
        overflow-y: auto;
        // padding: 20px;
        box-sizing: border-box;

        .mdTitle {
            // height: 50px;
            font-size: 24px;
            font-family: PingFang SC-粗体, PingFang SC;
            font-weight: bold;
            color: #313131;
            text-align: left;
            margin-top: 20px;
        }

        img {
            display: inline-block;
            max-width: 100%;
        }

        .linkBox {
            width: 100%;
            height: 32px;
            // margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            cursor: pointer;

            // margin-bottom: 24px;
            span:hover {
                color: #0068b2;
            }
        }

        .otherBox {
            padding: 20px 0;
            width: calc(100% - 40px);
            margin-left: 20px;
            border-bottom: 1px solid #D8D8D8;
        }

        .otherBox:last-of-type {
            border-bottom: none;
            border-top: 1px solid #D8D8D8;
        }

        .markdown-body {
            box-sizing: border-box;
            // min-width: 200px;
            // max-width: 980px;
            width: 100%;
            margin: 0 auto;
            padding: 10px 30px;
            overflow-y: auto;
            text-align: left;
        }

        // @media (max-width: 767px) {
        //     .markdown-body {
        //         padding: 15px;
        //     }
        // }

    }

    .loadingBox {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loadingModel {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background: #ffffffc9;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .activeBreadcrumbItem {
        font-weight: normal;
        color: #000000;
    }
}

.searchOptionItemBox {
    font-size: 12px;
    color: #787878;

    h4 {
        font-size: 14px;
        margin: 0;
    }

    a {
        cursor: unset;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: break-spaces;
        color: #787878;
    }
}

.searchDrawerClass {
    .ant-drawer-body {
        padding: 0;

        .searchDrawerInput {
            width: calc(100% - 40px);
            margin: 10px 0 10px 20px;
        }

        .spinBox {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .fileLIstContent {
            width: 100%;

            box-sizing: border-box;

            .fileLenText {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.45);
                padding: 0 20px;
            }

            .fileItemContent {
                cursor: pointer;
                padding: 7px 20px 12px;

                &:hover {
                    background-color: #f6f6f6;
                }

                .fileItemTitle {
                    font-weight: bold;
                    font-size: 14px;
                    color: #181818;
                    height: 30px;
                    display: flex;
                    align-items: center;

                    &:hover {
                        color: #0068b2;
                    }
                }

                .fileItemText {
                    font-size: 12px;
                    color: #666;
                    margin: 6px 0;
                }

                .fileItemLink {
                    display: flex;
                    display: flex;
                    align-items: center;
                    color: #9b9b9b;
                    font-size: 12px;
                    .fileItemLinkSpan:hover{
                        color: #0068b2;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .myContent {
        .catalogueBox {
            width: 212px;
        }

        .articleBox {
            width: calc(100% - 236px);
        }
    }
}